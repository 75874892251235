import React from "react";
import styled from "styled-components"

const Container = styled.div`
  display: inline-block;
  width: ${(props) => {
    return props.width ? props.width : "216px";
  }};
  position: relative;
  border-radius: 18px;
`;

const Message = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: red;
`;

const MessageBox = (props) => {
  return (
    <Container width={props.width}>
      <Message>{props.message}</Message>
    </Container>
  );
};

export default MessageBox;
